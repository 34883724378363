<template>
  <div class="recordDataTable-component">
    <a-anchor>
      <a-row
        type="flex"
        justify="space-between"
        align="middle"
        id="tableAction"
      >
        <a-button type="primary" @click="exportExcel">匯出(.xlsx)</a-button>
        <div v-if="data.length > 0" style="margin-right: 200px">
          總共筆數：{{ dataLength }}
        </div>
      </a-row>
      <a-table
        :pagination="tableSetting"
        :bordered="true"
        :columns="tableColumns"
        :data-source="data"
        :scroll="{ x: 600, y: windowHeight - 110 }"
        :key="componentKey"
        :loading="loading"
      >
        <template #action="{ record }">
          <a @click="action1(record.recordNo)">{{ record.date }}</a>
        </template>
      </a-table>
    </a-anchor>
  </div>
</template>

<script>
  import { reactive, computed, ref } from 'vue'
  import { numberComma } from '@/utils/index'
  export default {
    props: ['searchResult', 'loading'],
    setup(props, { emit }) {
      const data = computed(() => props.searchResult)
      const selectType = ref('basic')
      const componentKey = ref(1)
      const windowHeight = window.innerHeight
      const dataLength = computed(() => numberComma(data.value.length))
      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })
      const tableColumns = [
        {
          title: '量測日期',
          width: 120,
          dataIndex: 'date',
          key: 'date',
          fixed: 'left',
          align: 'center',
          type: 'all',
          slots: { customRender: 'action' },
          defaultSortOrder: 'descend',
          sorter: (a, b) => {
            return ('' + a.date).localeCompare(b.date)
          },
        },
        {
          title: '會員名稱',
          width: 130,
          key: 'user',
          fixed: 'left',
          align: 'center',
          type: 'all',
          dataIndex: 'user.name',
        },
        {
          title: '量測門市',
          key: 'user_store',
          align: 'center',
          type: 'basic',
          dataIndex: 'user.store',
        },
        {
          title: '收縮壓',
          key: 'systolic',
          align: 'center',
          type: 'basic',
          dataIndex: 'bpMeasurements[0].systolic',
        },
        {
          title: '舒張壓',
          key: 'diastolic',
          align: 'center',
          type: 'basic',
          dataIndex: 'bpMeasurements[0].diastolic',
        },
        {
          title: '血氧',
          key: 'spo2',
          align: 'center',
          type: 'basic',
          customRender: ({ record }) => {
            if (record.oxygenSatMeasurements.length === 0) return ''
            return `${record.oxygenSatMeasurements[0].spo2Lowest} - ${record.oxygenSatMeasurements[0].spo2Highest}`
          },
        },
        {
          title: '血糖',
          key: 'bloodGlucose',
          align: 'center',
          type: 'basic',
          dataIndex: 'glucoseMeasurements[0].bloodGlucose',
        },
        {
          title: 'BMI',
          key: 'bmi',
          align: 'center',
          type: 'basic',
          dataIndex: 'bodyWeightFatMeasurements[0].bmi',
        },
      ]

      const action1 = (value) => {
        emit('action1', value)
      }
      const action2 = (value) => {
        emit('action2', value)
      }
      const action3 = (value) => {
        emit('action3', value)
      }
      const action4 = (value) => {
        emit('action4', value)
      }
      const exportExcel = () => {
        emit('export-excel')
      }

      return {
        tableColumns,
        tableSetting,
        data,
        dataLength,
        action1,
        action2,
        action3,
        action4,
        exportExcel,
        selectType,
        componentKey,
        windowHeight,
      }
    },
  }
</script>

<style lang="less">
  .recordDataTable-component {
    #tableAction {
      .ant-radio-group,
      button {
        z-index: 5;
      }
    }
    .ant-pagination.ant-table-pagination {
      text-align: right;
      margin: -25px 0 0 0;
    }

    .ant-table-thead > tr {
      th {
        text-align: center;
        height: 60px;
      }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 5px;
    }
  }
</style>
