<template>
  <div class="healthRecord-container">
    <a-page-header title="健康記錄">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
      <Search :filter="filter" @on-search="handleSearch" />
      <RecordDataTable
        :searchResult="searchResult"
        :loading="loading"
        @action1="handelAction1"
        @exportExcel="exportExcel"
      />
    </a-page-header>
  </div>
</template>

<script>
  import { QuestionCircleOutlined } from '@ant-design/icons-vue'
  import { notification } from 'ant-design-vue'
  import { reactive, ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import XLSX from 'xlsx'
  import export2Excel from '@/utils/export2Excel'
  import Search from './Search'
  import RecordDataTable from './RecordDataTable'
  import { getStoresMemberRecordTime } from '@/api/healthy'
  import { parseTime, round, dateAdd, mealString } from '@/utils/index'
  import {
    getMeasurementDisplayFields,
    preprocessBodyWeightFat,
  } from '@/utils/measurement'
  export default {
    name: 'healthRecord',
    components: {
      QuestionCircleOutlined,
      Search,
      RecordDataTable,
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const filter = reactive({
        date: [dateAdd(-30), dateAdd(-1)],
      })
      const searchResult = ref([])
      const loading = ref(false)
      const measurements = ref([]) // 量測記錄

      const handleSearch = async (filter) => {
        try {
          loading.value = true
          // API：取得指定多筆店家及時間範圍的會員量測資料
          const { data, status } = await getStoresMemberRecordTime({
            ...filter,
            responseFormat: 'records',
          })
          if (![200, 201].includes(status)) throw 'api error'
          measurements.value = data.data
          const recordData = await searchDataArrange(data.data)
          searchResult.value = recordData
        } catch (e) {
          console.log(e)
          notification.error({
            message: '查詢失敗',
          })
        } finally {
          loading.value = false
        }
      }

      // 整理api回傳資料
      const searchDataArrange = async (data) => {
        const recordData = []
        for (const item of data) {
          // 訪客
          if (item.subject == null) {
            recordData.push({
              ...item,
              recordNo: item.id,
              key: item.id,
              date: parseTime(item.created),
              updated: parseTime(item.updated),
              hubCode: item.hubCode,
              user: {
                name: '訪客',
                caseNo: '',
                memberID: '',
                store: item.location.title,
                status: '',
              },
            })
          } else {
            // 會員資料cache裡沒有值時
            let custInfo = {}
            if (item.subject.cache == null) {
              custInfo = await store.dispatch('cust/getCustInfo', {
                memberID: item.subject.memberID,
              })
            } else {
              custInfo = item.subject.cache
            }
            recordData.push({
              ...item,
              recordNo: item.id,
              key: item.id,
              date: parseTime(item.created),
              updated: parseTime(item.updated),
              hubCode: item.hubCode,
              user: {
                name: custInfo.name,
                caseNo: custInfo.cardNumber,
                memberID: custInfo.memberID,
                store: item.location.title,
                status: '',
              },
            })
          }
        }

        return recordData
      }

      const handelAction1 = (param) => {
        router.push({ path: `/healthRecord/${param}` })
      }
      // 身體組成欄位資訊
      const bodyWeightFatFields = getMeasurementDisplayFields('bodyWeightFat')
      const exportExcel = () => {
        let aoa = [
          [
            '紀錄編號',
            '量測日期時間',
            '上傳日期時間',
            '會員編號',
            '會員姓名',
            '量測小站編號',
            '量測門市',
            '收縮壓',
            '舒張壓',
            '脈壓差',
            '脈搏',
            '心律不整(ARR)',
            '早期收縮(PC)',
            '心房顫動(Afib)',
            '血壓量測時間',
            '血壓量測設備',
            '血糖',
            '用餐狀態',
            '血糖量測時間',
            '血糖量測設備',
            '血氧最高',
            '血氧最低',
            '脈搏最高',
            '脈搏最低',
            '血氧量測時間',
            '血氧量測設備',
            '體溫',
            '體溫量測時間',
            '體溫量測設備',
            // 體重相關數值
            ...bodyWeightFatFields.map((e) => e.displayName),
            '身體組成量測時間',
            '身體組成量測設備',
          ],
        ]
        for (const iterator of searchResult.value) {
          const preproccessBodyWeightFatData = iterator
            .bodyWeightFatMeasurements[0]
            ? preprocessBodyWeightFat(iterator.bodyWeightFatMeasurements[0])
            : undefined

          aoa.push([
            iterator.recordNo,
            iterator.date,
            iterator.updated,
            iterator.user.memberID,
            iterator.user.name,
            iterator.hubCode,
            iterator.user.store,
            iterator.bpMeasurements[0]?.systolic,
            iterator.bpMeasurements[0]?.diastolic,
            iterator.bpMeasurements[0]?.pulsePressureDifference,
            iterator.bpMeasurements[0]?.pulse,
            iterator.bpMeasurements[0]?.arr,
            iterator.bpMeasurements[0]?.pc,
            iterator.bpMeasurements[0]?.afib,
            parseTime(iterator.bpMeasurements[0]?.measured),
            iterator.bpMeasurements[0]?.deviceID,
            iterator.glucoseMeasurements[0]?.bloodGlucose,
            mealString(iterator.glucoseMeasurements[0]?.meal),
            parseTime(iterator.glucoseMeasurements[0]?.measured),
            iterator.glucoseMeasurements[0]?.deviceID,
            iterator.oxygenSatMeasurements[0]?.spo2Highest,
            iterator.oxygenSatMeasurements[0]?.spo2Lowest,
            iterator.oxygenSatMeasurements[0]?.pulseHighest,
            iterator.oxygenSatMeasurements[0]?.pulseLowest,
            parseTime(iterator.oxygenSatMeasurements[0]?.measured),
            iterator.oxygenSatMeasurements[0]?.deviceID,
            iterator.bodyTempMeasurements[0]?.bodyTemperature
              ? round(
                  Number(iterator.bodyTempMeasurements[0]?.bodyTemperature),
                  1
                )
              : '',
            parseTime(iterator.bodyTempMeasurements[0]?.measured),
            iterator.bodyTempMeasurements[0]?.deviceID,
            // 體重相關數值
            ...bodyWeightFatFields.map((e) => {
              // 沒資料 or 不支援的欄位
              if (
                preproccessBodyWeightFatData === undefined ||
                !e.supportDevices.includes(
                  preproccessBodyWeightFatData.deviceID
                )
              )
                return ''
              return preproccessBodyWeightFatData[e.field]
            }),
            parseTime(iterator.bodyWeightFatMeasurements[0]?.measured),
            iterator.bodyWeightFatMeasurements[0]?.deviceID,
          ])
        }
        let worksheet1 = XLSX.utils.aoa_to_sheet(aoa)
        const now = parseTime(Date.now(), '{y}{m}{d}{h}{i}{s}')
        export2Excel({
          worksheets: {
            sheet1: worksheet1,
          }, // 匯出excel的資料，key表示工作表名，value表示對應工作表的 sheet 資料，支援匯出多個工作表
          fileName: `HealthRecord_${now}`, // 匯出檔名
          type: 'xlsx', // 檔案匯出型別
        })
      }

      return {
        filter,
        loading,
        handleSearch,
        searchResult,
        handelAction1,
        exportExcel,
      }
    },
  }
</script>
<style lang="less"></style>
