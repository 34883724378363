import { render } from "./RecordDataTable.vue?vue&type=template&id=4bc8f8b7"
import script from "./RecordDataTable.vue?vue&type=script&lang=js"
export * from "./RecordDataTable.vue?vue&type=script&lang=js"

import "./RecordDataTable.vue?vue&type=style&index=0&id=4bc8f8b7&lang=less"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4bc8f8b7"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4bc8f8b7', script)) {
    api.reload('4bc8f8b7', script)
  }
  
  module.hot.accept("./RecordDataTable.vue?vue&type=template&id=4bc8f8b7", () => {
    api.rerender('4bc8f8b7', render)
  })

}

script.__file = "src/views/healthManagement/healthRecord/RecordDataTable.vue"

export default script