<template>
  <div class="search-box">
    <div class="title">查詢條件</div>
    <div class="container">
      <div style="margin-top: 10px">
        量測時間
        <a-range-picker
          :format="dateFormat"
          style="margin-left: 20px"
          v-model:value="searchData.date"
        />
      </div>
      <div style="margin-top: 10px">
        量測門市
        <a-button
          type="primary"
          @click="
            () => {
              storeModalVisible = true
            }
          "
        >
          選取門市
        </a-button>
        <div>
          <StoreTags />
        </div>
      </div>
      <a-button
        type="primary"
        @click="handleSearch"
        :disabled="searchData.store_id.length == 0"
      >
        查詢
      </a-button>
    </div>
    <a-modal
      v-model:visible="storeModalVisible"
      :footer="null"
      title="選擇門市或分區"
      @cancel="getStoreList"
    >
      <StoreTags />
      <div style="margin-top: 12px">
        <StoreTreeSelector />
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { onMounted, reactive, ref, computed, watch } from 'vue'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import StoreTreeSelector from '@/components/StoreTreeSelector'
  import StoreTags from '@/components/StoreTags'

  export default {
    props: ['filter'],
    methods: {
      moment,
    },
    components: {
      StoreTreeSelector,
      StoreTags,
    },
    setup(props, { emit }) {
      const store = useStore()
      const storeModalVisible = ref(false)
      const storeChecked = computed(
        () => store.getters['store/checkedListTree']
      )
      const searchData = reactive({
        ...props.filter,
        store_id: [],
      })

      const getStoreList = () => {
        searchData.store_id = storeChecked.value
          .filter((item) => item.split('-').length == 4)
          .map((item) => parseInt(item.split('-').pop()))
        // userDateRecord.value = []
        // dateRecord.value = {}
        // uniqueDate = []
      }
      watch(storeChecked, () => {
        getStoreList()
      })

      const handleGetStoreValue = (value) => {
        searchData.store_id = value.store_id
      }

      const handleSearch = () => {
        emit('on-search', { ...searchData })
      }

      onMounted(async () => {})
      return {
        storeModalVisible,
        handleGetStoreValue,
        dateFormat: 'YYYY/MM/DD',
        searchData,
        handleSearch,
        getStoreList,
      }
    },
  }
</script>

<style lang="less">
  .search-box {
    .container {
      margin-left: 60px;
    }
    background-color: #fff;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    padding: 20px;

    .ant-collapse-content {
      background-color: #fff;
    }

    button {
      margin: 10px;
    }
  }
</style>
